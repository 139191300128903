// import { Container } from "reactstrap";
import { useState, useEffect, Fragment } from "react";
import stylesheet from "./NewExpiredLabel.scss";
import FontAwesome from "react-fontawesome";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';
import { getJwt, signOut } from "../../libraries/auth";
import jwt from "jsonwebtoken";
import axios from "axios";
import Moment from 'react-moment';
import { useRouter } from 'next/router'
import { getCookie, removeCookie, setCookie, setCookieWithExpire } from "../../libraries/session";
import { getData, putData } from "../../libraries/request";
import { structuredEvent } from "../../libraries/tracker";
import redirect from "../../libraries/redirect";

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { PRO_URL, IDENTITY_URL } = publicRuntimeConfig;
const NewExpiredLabel = (props) => {
    const router = useRouter()
    const [modal, setModal] = useState(false);
    const [expiredStatus, setExpiredStatus] = useState(false);
    const [expiredDate, setExpiredDate] = useState(false);
    const [gradePeriodStatus, setGradePeriodStatus] = useState(false);
    const [gradePeriodOneDayStatus, setGradePeriodOneDayStatus] = useState(false);
    const [gracePeriodLastDayStatus, setGracePeriodLastDayStatus] = useState(false);
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const { t, i18n, returnURL } = props;

    useEffect(async () => {
        async function checkStatusUser(){
            const token = getJwt();
            const decodedToken = jwt.decode(token);
            if(decodedToken){
                const userEmail = decodedToken.eml;
                const userName = decodedToken.unm;
                setEmail(userEmail);
                setUserName(userName);
                const checkExpiredUserCookie = getCookie("check-expired-user");
                let checkExpired;
                if(checkExpiredUserCookie && checkExpiredUserCookie !== 'undefined'){
                    checkExpired = JSON.parse(checkExpiredUserCookie);
                } else {
                    checkExpired = await checkExpiredUser(userEmail);
                    setCookieWithExpire("check-expired-user", JSON.stringify(checkExpired), 0.00138);
                }
                expiredInit(checkExpired)
            }
        }

        checkStatusUser()
    }, [])

    const checkExpiredUser = async (email) => {
        try {
            const jwt = getCookie("jwt");
            // const request = await axios.get(`https://servicepcapidev.hukum.io/membership-status/status?email=${email}`, headers);
            const request = await getData(`/membership-status/status?email=${email}`, jwt);
            return request.data;
        } catch (error) {
            return false;
        }
    }

    const skipHandle =  async () => {
        try {
            const jwt = getCookie("jwt");
            // const request = await axios.put(`http://servicepcapidev.hukum.io/membership-status/notif?email=${email}`);
            await putData(`/membership-status/notif?email=${email}`, {}, jwt);

            structuredEvent('all page (pro, pusat data, homepage, search) ', 'click button nanti saja   - notifikasi ', '', '', '')
            removeCookie("check-expired-user")
            setExpiredStatus(false)
            setGradePeriodStatus(false)
        } catch (error) {
            return error;
        }
    }

    const expiredInit = (data) => {
        const pathname = router.pathname;
        if(!data) return false;
        const { interval, isResponded, expirationDate, graceExpirationDate } = data;
        if(!interval) return false;
        if(isResponded) return false;

        if(interval === "expired") {
            signOut()
            redirect(`${IDENTITY_URL}/user/logout/returnUrl/${returnURL}`);
        }

        const statusExpiredCode = interval?.substring(1, 2);
        const dayInterval = interval?.substring(2, 4);

        if(!statusExpiredCode && pathname !== "/pro/perpanjanglangganan"){
            const notifExpiredCounter = getCookie("expired-notif-counter");
            if(!notifExpiredCounter || notifExpiredCounter === "0") {
                setModal(true)
            } else {
                const reduceNotifExpiredCounter = parseInt(notifExpiredCounter) - 1;
                setCookieWithExpire("expired-notif-counter", reduceNotifExpiredCounter, 1);
            }
        }

        if(statusExpiredCode === "-"){
            if(parseInt(dayInterval) !== 1) {
                setExpiredDate(expirationDate)
            }
            setExpiredStatus(true);
        }

        if(statusExpiredCode === "+"){
            if(parseInt(dayInterval) === 1 || parseInt(dayInterval) === 14) {
                setExpiredDate(graceExpirationDate)
            }

            if(parseInt(dayInterval) === 30) {
                setGradePeriodOneDayStatus(true)
            }

            if(parseInt(dayInterval) === 31) {
                setGracePeriodLastDayStatus(true)
            }
            setGradePeriodStatus(true);
        }
    }

    const notifExpired = () => {
        return (
            <div className="containerExpired align-items-center d-flex flex-column flex-md-row flex-lg-row">
            <div className="d-flex flex-row align-items-start">
                <FontAwesome name="exclamation-triangle" className="warning mt-1"/>
                <div className="d-flex flex-column">
                    {expiredDate &&
                        <p className="expired-title m-0 p-0">{t("renewal-reminder")}
                        {" "}
                            <Moment locale={i18n.language} format="D MMMM  YYYY">
                            {expiredDate}
                            </Moment>
                        </p>
                    }
                    {!expiredDate && <p className="expired-title m-0 p-0">{t("renewal-reminder-tomorrow")}</p>}

                    <p className="expired-subtitle m-0 p-0">{t("renewal-reminder-subtitle")}</p>
                </div>
            </div>
            <div className="ml-md-auto button-box-mobile">
                <button className="button-skip button-skip-mobile" onClick={() => skipHandle()}>{t("nanti-saja")}</button>
                <button className="button-extend button-extend-mobile">
                    <a
                    href={`${PRO_URL}/perpanjang-langganan`}
                    onClick={() => structuredEvent('all page (pro, pusat data, homepage, search) ', 'click button perpanjang sekarang  - notifikasi ', '', '', '')}>
                        {t("perpanjang-sekarang")}
                    </a>
                </button>
            </div>
        </div>
        )
    }

    const notifGracePeriod = () => {
        return (
            <div className="containerGracePeriod align-items-center d-flex flex-column flex-md-row flex-lg-row">
                <div className="d-flex flex-row align-items-start">
                    <FontAwesome name="exclamation-circle" className="danger mt-md-1"/>
                    <div className="d-flex flex-column">
                        <p className="expired-title m-0 p-0">{t("grace-period")}</p>
                        <p className="expired-subtitle m-0 p-0">{t("grace-period-subtitle")}</p>
                    </div>
                </div>
                <div className="ml-md-auto button-box-mobile">
                    <button className="button-extend width-full"><a href={`${PRO_URL}/perpanjang-langganan`} >{t("perpanjang-sekarang")}</a></button>
                </div>
            </div>
        )
    }

    const toggle = () => {
        setCookieWithExpire("expired-notif-counter", 5, 1);
        setModal(!modal)
        structuredEvent('all page (pro, pusat data, homepage, search) ', 'click button close  - notifikasi ', '', '', '')
    };
    return (
        <Container className="expired-notif-container">
            <style dangerouslySetInnerHTML={{ __html: stylesheet }} />

            {expiredStatus && notifExpired()}
            {gradePeriodStatus && notifGracePeriod()}

            <Modal isOpen={modal} toggle={toggle}>
        {/* <ModalHeader toggle={toggle}></ModalHeader> */}
                <ModalBody>
                    {i18n.language === "id" &&
                        <div className='d-flex flex-column align-items-center justify-content-center modal-notif-expired col-12'>
                            <div className="close-icon"><FontAwesome name="close" onClick={() => toggle()}/></div>
                            <img src='/static/images/notif_expired/expired.png'/>
                            <p className="modal-notif-greeting">Dear {userName}</p>
                            <p className="modal-notif-intro">Masa Berlangganan Hukumonline Pro Anda Berakhir Hari Ini</p>
                            <p className="modal-notif-info">Sayang sekali, masa berlangganan akun Hukumonline Pro Anda sudah habis.</p>
                            <p>Segera perpanjang paket berlangganan Anda untuk terus menggunakan fitur dan layanan Hukumonline Pro. Klik tombol di bawah ini untuk perpanjang paket.</p>
                            <a href={`${PRO_URL}/perpanjang-langganan`} className="modal-notif-button">Perpanjang Sekarang</a>
                            <p className="modal-notif-greeting">Apabila membutuhkan bantuan, silakan hubungi customer@hukumonline.com.</p>
                        </div>
                    }
                    {i18n.language === "en" &&
                        <div className='d-flex flex-column align-items-center justify-content-center modal-notif-expired col-12'>
                            <div className="close-icon"><FontAwesome name="close" onClick={() => toggle()}/></div>
                            <img src='/static/images/notif_expired/expired.png'/>
                            <p className="modal-notif-greeting">Dear {userName}</p>
                            <p className="modal-notif-intro">Your Hukumonline Pro Subscription Ends Today</p>
                            <p className="modal-notif-info">We're sorry, your Hukumonline Pro subscription has ended.</p>
                            <p>Renew your subscription now to continue accessing the features and services of Hukumonline Pro. Click the button below to renew your plan.</p>
                            <a href={`${PRO_URL}/perpanjang-langganan`} className="modal-notif-button">Renew now</a>
                            <p className="modal-notif-greeting">If you need assistance, please contact customer@hukumonline.com.</p>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </Container>
    )
}

export default NewExpiredLabel;
