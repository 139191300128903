import React from 'react';
import Container from 'reactstrap/lib/Container';
// import { MdLocationPin, MdPhone, MdMail } from 'react-icons/md';
import { RiCopyrightFill } from 'react-icons/ri';
// import {
//   BsFacebook,
//   BsInstagram,
//   BsLinkedin,
//   BsYoutube,
//   BsTwitter,
// } from 'react-icons/bs';
// import { isMobile } from 'react-device-detect';
// import { withCommons } from '../../libraries/commons';
// import { structuredEvent } from '../../libraries/tracker';
import DesktopFooter from './dektopFooter';
import MobileFooter from './mobileFooter';
import useWindowSize from '../../hooks/useWindowSize';

import { useCookieConsent, CookiesConsent } from '../CookiesConsent';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, PRO_URL } = publicRuntimeConfig;

const Footer = (props) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const {
    darkMode = {},
    router: { route },
  } = props;
  const { toggle: isDarkMode = false } = darkMode;
  const logo =
    isDarkMode && route === '/premium/article'
      ? `${BASE_URL}/static/images/logo_white.svg`
      : `${BASE_URL}/static/images/logo.svg`;
  const logoKominfo =
    isDarkMode && route === '/premium/article'
      ? `${BASE_URL}/static/images/logo/kominfo-white-logo.png`
      : `${BASE_URL}/static/images/logo/kominfo-logo.png`;
  const textColor =
    isDarkMode && route === '/premium/article' ? 'text-white' : 'text-main';
  const bgColor =
    isDarkMode && route === '/premium/article' ? 'bg-black-light' : 'bg-light';
  const primaryTextColor =
    isDarkMode && route === '/premium/article'
      ? 'text-secondary'
      : 'text-primary';

  const { toggleSettings } = useCookieConsent();

  return (
    <>
      <CookiesConsent t={props.t} route={route} />
      {isMobile && (
        <div className="py-4 container">
          {' '}
          <MobileFooter
            t={props.t}
            logo={logo}
            textColor={textColor}
            primaryTextColor={primaryTextColor}
            logoKominfo={logoKominfo}
            bgColor={bgColor}
          />
        </div>
      )}
      <div
        className={`${
          isDarkMode && route === '/premium/article'
            ? 'bg-black-light'
            : 'bg-light'
        }`}
      >
        {!isMobile && (
          <div className="py-4 container">
            {' '}
            <DesktopFooter
              t={props.t}
              logo={logo}
              textColor={textColor}
              primaryTextColor={primaryTextColor}
              logoKominfo={logoKominfo}
            />
          </div>
        )}

        <div className="bg-primary py-1">
          <Container>
            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between w-100">
              <div className="d-flex align-items-center order-1 order-lg-0 justify-content-center">
                <RiCopyrightFill
                  fontSize="16px"
                  className="text-white my-2 mr-2"
                />
                <span className="small font-weight-semibold text-white">
                  {' '}
                  {new Date().getFullYear()} Hak Cipta Milik Hukumonline.com
                </span>
              </div>
              <div
                style={{ gap: '.25rem' }}
                className="d-flex align-items-center justify-content-center"
              >
                <button
                  onClick={() => toggleSettings(true)}
                  type="button"
                  className="__cookies_settings_footer"
                >
                  <span className="small font-weight-semibold text-white __cookies_settings_footer">
                    Cookies Settings
                  </span>
                </button>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="text-white d-inline-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                </svg>
                <a
                  href={`${PRO_URL}/cookies/`}
                  className="__detail_cookies_footer"
                >
                  <span className="small font-weight-semibold text-white __detail_cookies_footer">
                    Cookies Info
                  </span>
                </a>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Footer;
